<template>
  <v-container>
    <div v-show="!start">
      <p>{{ params.body }}</p>
      <v-divider />
      <br />
      <v-btn color="primary" large tile depressed @click="() => (start = true)">Start</v-btn
      >
      <br />
      <!-- <v-checkbox
        v-model="dontShowAgain"
        @change="checkDontShowAgain"
        label="Don't show again"
      ></v-checkbox> -->
    </div>
    <survey v-show="start" />
  </v-container>
</template>

<script>
import Survey from "./Survey";

export default {
  name: "SurveyDialog",
  components: { Survey },
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      start: false,
      dontShowAgain: false,
    };
  },
  mounted() {
    this.dontShowAgain = localStorage.getItem("survey:dontshowagain");
  },
  methods: {
    checkDontShowAgain() {
      if (this.dontShowAgain) {
        localStorage.setItem("survey:dontshowagain", this.dontShowAgain);
      } else {
        localStorage.removeItem("survey:dontshowagain");
      }
    },
  },
};
</script>
