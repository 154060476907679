<template>
  <span></span>
</template>

<script>
import { mapActions } from "vuex";
import Push from "push.js";

export default {
  name: "NativeNotification",
  data() {
    return {
      gotoText: this.$t("general.goto"),
    };
  },
  mounted() {
    this.init(this);
  },
  methods: {
    ...mapActions("ui", ["addToMenuBadge", "addNotification"]),
    ...mapActions("srs", ["setSrsReloadMode"]),

    init(self) {
      const { username, organizationId } = self.$getUserInfo();
      if (!username || !organizationId) {
        return;
      }

      Push.Permission.request();

      const isAdmin = this.$hasRole("Administrator");
      this.$wsConnect({ username, organizationId, isAdmin }, ({ body }) => {
        const data = JSON.parse(body);

        if (["create", "delete", "update", "upload"].includes(data.type)) {
          self.setSrsReloadMode(true);
        }

        if (data.from === username) {
          return;
        }

        if (data.notify) {
          const text =
            data.body && data.body.length > 250
              ? data.body.substr(0, 250)
              : data.body;

          self.addNotification({
            text,
            skipTranslation: true,
            color: "primary",
            timeout: 3000,
            alert: true,
          });

          if (!document.hasFocus()) {
            Push.create(data.type, {
              body: text,
              icon: "/logo.png",
              // requireInteraction: true,
              timeout: 3000,
              vibrate: [300, 200, 100],
              onClosed: function() {
                // goTo();
                window.focus();
              },
            });
          }
        }
      });
    },
  },
};
</script>
