<template>
  <survey class="survey" :survey="survey"></survey>
</template>

<script>
import * as SurveyVue from "survey-vue";
var Survey = SurveyVue.Survey;
Survey.cssType = "bootstrap";
// Survey.cssType = "modern";

export default {
  components: {
    Survey,
  },
  data() {
    var json = {
      pages: [
        {
          name: "default",
          elements: [
            {
              type: "text",
              name: "organization",
              title: "Organization Name (Optional)",
            },
            {
              type: "text",
              name: "name",
              title: "Name (Optional)",
            },
            {
              type: "rating",
              name: "is_simple",
              title: "The system is simple to use.",
              rateValues: [
                {
                  value: 1,
                  text: "(Strongly Disagree 👎) 1",
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: "5 (Strongly Agree 👍)",
                },
              ],
            },
            {
              type: "rating",
              name: "increases_productivity",
              title: "Using the system increases my productivity.",
              rateValues: [
                {
                  value: 1,
                  text: "(Strongly Disagree 👎) 1",
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: "5 (Strongly Agree 👍)",
                },
              ],
            },
            {
              type: "rating",
              name: "easy_to_learn",
              title: "I learned to use it quickly.",
              rateValues: [
                {
                  value: 1,
                  text: "(Strongly Disagree 👎) 1",
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: "5 (Strongly Agree 👍)",
                },
              ],
            },
            {
              type: "rating",
              name: "training_materials",
              title:
                "The training materials helped me answer any questions about the system.",
              rateValues: [
                {
                  value: 1,
                  text: "(Strongly Disagree 👎) 1",
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: "5 (Strongly Agree 👍)",
                },
              ],
            },
            {
              type: "rating",
              name: "satisfied",
              title: "I am satisfied with the platform.",
              rateValues: [
                {
                  value: 1,
                  text: "(Strongly Disagree 👎) 1",
                },
                2,
                3,
                4,
                {
                  value: 5,
                  text: "5 (Strongly Agree 👍)",
                },
              ],
            },
            {
              type: "comment",
              name: "how_to_improve",
              title:
                "How can we improve the system? Please share any ideas and suggestions.",
            },
          ],
        },
      ],
    };
    var model = new SurveyVue.Model(json);
    return {
      survey: model,
      username: null,
    };
  },
  mounted() {
    this.survey.onComplete.add(this.submit);
    const userInfo = this.$getUserInfo();
    this.username = userInfo.username;
  },
  methods: {
    submit(s) {
      let participants = localStorage.getItem("survey:general");
      if (!participants || participants.indexOf(this.username) !== -1) {
        participants = `${participants ? participants + "," : ""}${
          this.username
        }`;
        localStorage.setItem("survey:general", participants);
        s.sendResult("6b8bf4ce-85f8-4b47-84d5-555cec47c21b");
      }
    },
  },
};
</script>

<style>
.survey input[type="text"],
.survey textarea {
  border: 1px solid #999;
  border-radius: 4px;
  /* margin: 7px 12px; */
  min-width: 300px;
  padding: 2px;
}

.survey .sv_complete_btn {
  background-color: royalblue;
  color: #fff;
  border-radius: 4px;
  /* margin: 5px 12px; */
  padding: 7px 12px;
}

.survey label.btn-secondary {
  text-align: center;
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
  margin: 2px;
  padding: 3px;
  border-radius: 4px;
}
.survey label.btn-secondary.active {
  background-color: royalblue;
  color: #fff;
}
</style>
