<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      :right="getLanguage === 'ar'"
      color="grey lighten-4"
    >
      <v-app-bar
        flat
        color="primary"
        dark
        class="align-content-space-around title-bar"
      >
        <v-btn link fab small depressed href="#/" color="transparent">
          <v-img max-height="46" max-width="46" src="logo.png" style="border: solid 0px #fff; border-radius: 25px; background-color: #009edb;" />
        </v-btn>
        <v-toolbar-title class="text-uppercase font-weight-bold subtitle-1 py-0 px-2">
          UNDSS SRS
          <span style="display: block; position: absolute; top: 30px; font-size: 8pt; color: #fff"> {{ getAppVersion }}</span>
          </v-toolbar-title
        >
      </v-app-bar>
      <v-list dense>
        <div :class="$route.name === item.name ? 'active-route' : ''" v-for="item in navItems" :key="item.title">
          <v-divider v-if="item.divider && !hideNav(item)" />
          <v-list-item
            :link="!item.group"
            :to="getRoute(item)"
            v-if="!hideNav(item)"
          >
            <v-list-item-icon v-if="!item.group">
              <v-badge
                v-if="!!hasBadge(item.resource)"
                color="red"
                label
                bordered
                left
                overlap
                :content="getMenuBadgesCounter(item.resource)"
              >
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
              <v-icon v-if="!hasBadge(item.resource)">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content
              :class="`title${item.group ? ' text-uppercase' : ''}`"
            >
              <v-list-item-title :style="item.group ? 'font-size: 8pt;' : ''">
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="grey lighten-4" flat class="app-bar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <!-- <v-text-field
        hide-details
        prepend-icon="mdi-magnify"
        solo
        flat
        dense
        rounded
        clearable
        :placeholder="$t('general.search') + '...'"
        style="width: 300px;"
      ></v-text-field> -->

      <v-spacer></v-spacer>

      <v-menu left bottom style="z-index: 999;">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-web</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            @click="
              () => {
                setLanguage('en');
                $i18n.locale = 'en';
                changeRTL(false);
              }
            "
          >
            <v-list-item-title>English</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item
            @click="
              () => {
                setLanguage('uk');
                $i18n.locale = 'uk';
                //changeRTL(true);
              }
            "
          >
            <v-list-item-title>Українська</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn icon>
        <v-icon @click="fullscreen()">mdi-fullscreen</v-icon>
      </v-btn>

      <v-menu left bottom style="z-index: 999;">
        <template v-slot:activator="{ on }">
          <v-chip color="white" class="body-2 pl-2 pr-0" large>
            {{
              `${getCurrentUser.organizationAcronym}/${getCurrentUser.username}`
            }}
            <v-btn icon v-on="on" class="pa-0 ma-1">
              <img
                :src="getAvatar"
                :alt="getCurrentUser.username"
                height="50"
                width="50"
              />
            </v-btn>
          </v-chip>
        </template>

        <v-list dense>
          <v-list-item @click="() => $router.push({ name: 'profile' })">
            <v-list-item-title>{{ $t("route.profile") }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item @click="tryLogout">
            <v-list-item-title>{{ $t("login.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="main-content d-flex align-start">
      <!-- <v-toolbar class="grey lighten-5 d-none d-md-block" flat height="22">
        <template>
          <v-icon small>mdi-play</v-icon>
          <v-breadcrumbs small :items="getBreadcrumbs" class="px-1" style="border-bottom: dashed 1px #eee;">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                :href="item.link"
                :disabled="item.disabled"
                class="caption text-uppercase"
              >
                {{ item.skipTranslate ? item.name : $t(item.name) }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </template>
      </v-toolbar> -->
      <v-container class="fill-height py-0 px-0" fluid>
        <transition name="router-animation">
          <router-view></router-view>
        </transition>
        <v-footer color="primary" style="z-index: 1; width: 100%; height: 20px; padding: 0;">
          <copyright>
              <span class="mx-3 my-0" style="font-size: 8pt; color: #fff">VERSION: {{ getAppVersion }}</span>
          </copyright>
          <v-spacer />
          <span
            class="px-3 blue--text text--lighten-5 caption"
            v-text="getLoadingMessage"
          ></span>
          <v-progress-circular
            v-if="getLoadingState"
            :indeterminate="getLoadingState"
            color="blue lighten-5"
            size="20"
          ></v-progress-circular>
        </v-footer>
      </v-container>
    </v-main>
    <native-notification />
  </div>
</template>

<script>
import navigations from "./navigations";
import { mapGetters, mapActions } from "vuex";
import Copyright from "@/common/components/Copyright";
import NativeNotification from "@/common/components/NativeNotification";
import SurveyDialog from "@/common/extra/SurveyDialog";
import moment from "moment";

export default {
  name: "AppLayout",
  components: {
    Copyright,
    NativeNotification,
    // eslint-disable-next-line vue/no-unused-components
    SurveyDialog,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: true,
    navItems: [],
  }),
  mounted() {
    this.navItems = navigations.map((m) => {
      return m.resource
        ? Object.assign(m, { can: this.$allowed(m.resource) })
        : m;
    });

    setTimeout(() => {
      this.launchSurvey(true);
    }, 3000);
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("ui", [
      "getBreadcrumbs",
      "getLoadingState",
      "getLoadingMessage",
      // "isCommentsEnabled",
      "getLanguage",
      "getMenuBadges",
      "getMenuBadgesCounter",
    ]),

    getAppVersion() {
      return process.env.VERSION;
    },

    getAvatar() {
      return `${process.env.ROOT_API}/files/avatar/${this.getCurrentUser.username}`;
    },
    getSurveyNotExpired() {
      return moment().isBefore("2021-04-19");
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("ui", ["setLanguage", "showDialog", "closeDialog"]),

    launchSurvey(forceShow = false) {
      if (this.getSurveyNotExpired) {
        const userInfo = this.$getUserInfo();
        const dontShowAgain = localStorage.getItem("survey:dontshowagain");
        const participants = localStorage.getItem("survey:general") || "";
        if (
          forceShow ||
          !dontShowAgain ||
          (participants && participants.indexOf(userInfo.username) === -1)
        ) {
          this.showSurveyDialog();
        }
      }
    },

    hasBadge(resource) {
      const badge = this.getMenuBadges(resource);
      return this.notEmptyArray(badge);
    },
    getRoute(item) {
      const badges = this.getMenuBadges(item.resource);
      if (badges && item.to) {
        return {
          name: item.to,
          query: { highlight: badges.join(",") },
        };
      } else if (item.to) {
        return { name: item.to };
      }

      return null;
    },
    tryLogout() {
      this.logout();
      this.$router.push({ name: "login" });
    },
    fullscreen() {
      this.$fullscreen.toggle();
    },
    hideNav(item) {
      if (item.group) {
        const groupItems = this.navItems.filter(
          (i) => !i.group && i.groupId && i.groupId === item.groupId && i.can
        );
        return groupItems.length === 0;
      }

      const hide = !item.can;
      return hide;
    },
    showSurveyDialog() {
      const dialog = {
        // title: this.$t("general.verifyProject"),
        title: "SRS Tracking System – Feedback",
        component: SurveyDialog,
        params: {
          item: {},
          body:
            "Thank you for participating in the Sub-Agreements Tracking System training sessions.  We are always looking for ways to improve the system.  Please take a moment to tell us about your experience.",
        },
        width: 650,
        actions: [
          {
            text: this.$t("general.close"),
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };
      this.showDialog(dialog);
    },
  },
};
</script>

<style>
.main-content {
  overflow: hidden;
}

.router-animation-enter-active {
  animation: coming 0.5s;
  animation-delay: 0.5s;
  opacity: 0;
}
.router-animation-leave-active {
  animation: going 0.5s;
}

@keyframes going {
  from {
    /** transform: translateX(0); **/
  }
  to {
    /** transform: translateX(-50px); **/
    opacity: 0;
  }
}

@keyframes coming {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.v-data-table td,
.v-data-table th {
  font-size: 0.75rem;
  padding: 0 8px;
}

.col-12 {
  padding-top: 0;
}

.v-navigation-drawer__content {
  box-shadow: -4px 0px 4px -3px rgba(0,0,0,0.35) inset;
  -webkit-box-shadow: -4px 0px 4px -3px rgba(0,0,0,0.35) inset;
  -moz-box-shadow: -4px 0px 4px -3px rgba(0,0,0,0.35) inset;
}

.title-bar .v-toolbar__content {
  border-bottom: 3px solid #0086bb;
}

.app-bar .v-toolbar__content {
  border-bottom: 1px solid #d6eaf3;
  background-color: #ffff;
}
</style>
