const navigations = [
  {
    title: "route.dashboards",
    resource: "dashboards",
    icon: "mdi-chart-bar",
    link: "/#/dashboards",
    to: "dashboards",
  },
  {
    title: "route.reports",
    resource: "reports",
    icon: "mdi-file-table-box-multiple",
    link: "/#/reports",
    to: "reports",
  },
  {
    title: "route.ac",
    divider: true,
    group: true,
    groupId: "ac",
  },
  // {
  //   title: "route.orgs",
  //   resource: "organizations",
  //   icon: "mdi-domain",
  //   link: "/#/orgs",
  //   to: "orgs",
  //   groupId: "ac",
  // },
  {
    title: "route.users",
    resource: "users",
    icon: "mdi-account-multiple",
    link: "/#/ac/users",
    to: "users",
    groupId: "ac",
  },
  {
    title: "route.roles",
    resource: "roles",
    icon: "mdi-shield-lock",
    link: "/#/ac/roles",
    to: "roles",
    groupId: "ac",
  },
  // {
  //   title: "route.aor",
  //   resource: "aor",
  //   icon: "mdi-set-left-center",
  //   link: "/#/ac/aor",
  //   to: "aor",
  //   groupId: "ac",
  // },


  {
    title: "route.settings",
    divider: true,
    group: true,
    groupId: "settings",
  },
  {
    title: "route.bulkImport",
    resource: "bulkimport",
    icon: "mdi-file-excel",
    link: "/#/settings/import",
    to: "import",
    groupId: "settings",
  },
  // {
  //   title: "route.dataTypes",
  //   resource: "datatypes",
  //   icon: "mdi-cog",
  //   link: "/#/settings/types",
  //   to: "types",
  //   groupId: "settings",
  // },
  {
    title: "options.title",
    resource: "notifications",
    icon: "mdi-tune-vertical",
    link: "/#/settings/options",
    to: "options",
    groupId: "settings",
  },
  {
    title: "route.profile",
    resource: "profile",
    icon: "mdi-account-circle",
    link: "/#/profile",
    to: "profile",
    groupId: "settings",
  },
];


function dynamicNavigations() {
  let menu = [];
  // Define the base directory to search for route.js files
  const menuFiles = require.context("@/features", true, /\/scripts\/index\.js$/)

  // Iterate over all matching files and call the getRoutes function
  const fileKeys = menuFiles.keys();
  fileKeys.forEach((key) => {
    const module = menuFiles(key);
    console.log(module);
    if (module.getMenu) {
      const featureMenu = module.getMenu();
      menu = [...menu, ...featureMenu];
    }
  })

  const m = [...menu, ...navigations];
  return m;
}

export default dynamicNavigations();